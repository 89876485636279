.notify {
  z-index: 9999;
  -o-transition: all .4s ease-in-out;
  border-radius: 4px;
  width: 400px;
  padding: 15px;
  transition: all .4s ease-in-out;
  position: fixed;
}

.notify > button.close {
  -webkit-appearance: none;
  cursor: pointer;
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  filter: alpha(opacity= 20);
  opacity: .2;
  background: none;
  border: 0;
  outline: none;
  padding: 0;
  font-size: 23px;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  top: 8px;
  right: 12px;
}

.notify > button.close:hover {
  filter: alpha(opacity= 50);
  opacity: .5;
}

.notify-dismissible .message {
  padding-right: 25px;
}

.notify.scale {
  opacity: 0;
  transform: scale(.8);
}

.notify.left.drop {
  opacity: 0;
  transform: translateX(-50%);
}

.notify.center.drop {
  opacity: 0;
  transform: translateY(-120%);
}

.notify.right.drop {
  opacity: 0;
  transform: translateX(50%);
}

.notify.middle.center.drop {
  opacity: 0;
  transform: translateY(-20%);
}

.notify.bottom.center.drop {
  opacity: 0;
  transform: translateY(120%);
}

.notify.fade, .notify.out {
  opacity: 0;
}

.notify-default {
  color: #333;
  background-color: #fff;
  box-shadow: 0 3px 10px #0003;
}

.notify-info {
  color: #31708f;
  background-color: #d9edf7;
}

.notify-toast {
  color: #fff;
  background-color: #000000bf;
}

.notify-danger {
  color: #a94442;
  background-color: #f2dede;
}

.notify-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
}

.notify-success {
  color: #3c763d;
  background-color: #dff0d8;
}

.notify.top {
  top: 15px;
}

.notify.middle {
  top: 50%;
}

.notify.bottom {
  bottom: 15px;
}

.notify.left {
  left: 15px;
}

.notify.center {
  margin-left: -200px;
  left: 50%;
}

.notify.right {
  right: 15px;
}

.notify-buttons {
  width: 100%;
  margin-top: 10px;
}

.notify-buttons.left {
  text-align: left;
}

.notify-buttons.center {
  text-align: center;
}

.notify-buttons.right {
  text-align: right;
}

.notify-buttons > button {
  color: #333;
  cursor: pointer;
  background: #fff;
  border: 1px solid #ddd;
  outline: none;
  padding: 4px 10px;
}

.notify-buttons > button:hover {
  background: #eee;
}

.notify-buttons > button:first-child {
  margin-right: 5px;
}

.notify-backdrop {
  z-index: 9998;
  opacity: 0;
  -o-transition: opacity .4s ease-in-out;
  background-color: #000;
  width: 100%;
  height: 100%;
  transition: opacity .4s ease-in-out;
  position: fixed;
  inset: 0;
}

body.notify-open {
  overflow: hidden;
}

body.notify-open-drop {
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .notify {
    border-radius: 0;
    width: 100%;
    margin: 0 !important;
    left: 0 !important;
  }

  .notify.top {
    top: 0 !important;
  }

  .notify.bottom {
    bottom: 0 !important;
  }

  .notify.middle {
    border-radius: 4px;
    width: 80% !important;
    margin-left: 10% !important;
  }

  .notify.left.drop, .notify.right.drop {
    transform: translateY(-120%);
  }

  .notify.bottom.drop {
    opacity: 0;
    transform: translateY(120%);
  }
}

/*# sourceMappingURL=index.8241033b.css.map */
